import {FileSourceType, FileType} from "./file.type"
import {FFileOpenApi, FileSourceOpenApi} from "../../generated"

export function mapOpenApiToFile(file: FFileOpenApi): FileType {
    return {
        id: file.id,
        sequentialId: file.sequentialId,
        path: file.path,
        source: mapOpenApiToFileSource(file.source!),
        type: file.type,
        mimeType: file.mimeType,
    }
}

export function mapFileToOpenApi(file: FileType): FFileOpenApi {
    return {
        id: file.id,
        sequentialId: file.sequentialId,
        path: file.path,
        type: file.type,
        mimeType: file.mimeType
    }
}

function mapOpenApiToFileSource(source: FileSourceOpenApi): FileSourceType {
    return {
        type: source.type,
        src: source.src
    }
}