import React, {FunctionComponent} from "react"
import styles from "./disclaimer.module.sass"
import Tooltip from "../../../../tech/layout/tooltip/tooltip.component"
import DealDisclaimerTooltipContent from "./tooltip-content.component"

const DealDisclaimer: FunctionComponent = () => {
    return (
        <div className={styles.disclaimer}>
            <div className={styles.title}>
                <div className={styles.titleText}>
                    Disclaimer
                </div>
                <div className={styles.info}>
                    <Tooltip
                        infoIconSize={20}
                        maxWidth={280}
                        position={{
                            top: 16,
                            left: -44,
                            shiftLeft: -60
                        }}
                    >
                        <DealDisclaimerTooltipContent/>
                    </Tooltip>
                </div>
            </div>
            <div className={styles.readFirst}>
                Please read before you proceed.
            </div>
        </div>
    )
}

export default DealDisclaimer