import React, {FunctionComponent} from "react"
import styles from "./notice.module.sass"

const CompanyMissingUpdatesNotice: FunctionComponent = () => {
    return (
        <div className={styles.notice}>
            <strong>Update:</strong> We are committed to keeping our investors informed and have actively followed up with the startup team for their latest updates over the past few weeks.<br/>
            Unfortunately, we haven't received a response yet. We’ll update this space as soon as we hear back. Thank you for your understanding.
        </div>
    )
}

export default CompanyMissingUpdatesNotice