import {UpdateFileOpenApi, UpdateForDealOpenApi} from "../../generated"
import {UpdateFileType, UpdateType} from "./update.type"
import {mapOpenApiToFile} from "../file/file.mapper"

export function mapOpenApiToUpdate(update: UpdateForDealOpenApi): UpdateType {
    return {
        id: update.id,
        date: update.date,
        description: update.description,
        files: update.files.map(mapOpenApiToUpdateFile)
    }
}

export function mapOpenApiToUpdateFile(updateFile: UpdateFileOpenApi): UpdateFileType {
    return {
        title: updateFile.title,
        sequentialId: updateFile.sequentialId,
        order: updateFile.order,
        file: mapOpenApiToFile(updateFile.file)
    }
}