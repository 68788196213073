import {InvestmentType} from "../investment/investment.type"
import {mapOpenApiToInvestment} from "../investment/investment.mapper"
import {DealInvestmentInitializationType} from "./invest/initialization.type"
import {mapInitializationToOpenApi} from "./invest/initialization.mapper"
import {DealQuestionType} from "./question/question.type"
import {mapOpenApiToDealQuestion} from "./question/question.mapper"
import {DealFeeStructureType} from "./fee-structure/deal-fee-structure.type"
import {mapOpenApiToDealFeeStructure} from "./fee-structure/deal-fee-structure.mapper"
import {InvestmentFeesResponseType} from "../investment/fee/fees.type"
import {mapOpenApiToInvestmentFeesResponse} from "../investment/fee/fees.mapper"
import {mapOpenApiToDealOnboardingDependentWithInvestment} from "./onboarding-dependent.mapper"
import {DealOnboardingDependentWithInvestmentType} from "./onboarding-dependent.type"
import {DealApiClient, DealOnboardingDependentWithInvestmentOpenApi} from "../../generated"
import {DealWithSyndicatePublicType} from "./public/deal-public.type"
import {mapOpenApiToDealWithSyndicatePublic} from "./public/deal-public.mapper"

export class DealApi {

    constructor(private apiClient: DealApiClient) {
    }

    public async getAllOpen(
        exclusivesOnly: boolean,
        favouritesOnly: boolean
    ): Promise<DealOnboardingDependentWithInvestmentType[]> {
        return (await this.apiClient.dealGetAllOpen({
                exclusivesOnly,
                favouritesOnly
            }))
            .map((dealWithInvestment: DealOnboardingDependentWithInvestmentOpenApi) => mapOpenApiToDealOnboardingDependentWithInvestment(dealWithInvestment))
    }

    public async getWithInvestment(id: string): Promise<DealOnboardingDependentWithInvestmentType> {
        const dealWithInvestment = await this.apiClient.dealOnboardingDependentWithInvestmentGet({ id })
        return mapOpenApiToDealOnboardingDependentWithInvestment(dealWithInvestment)
    }

    public async getInvestmentsFees(id: string, amount: number): Promise<InvestmentFeesResponseType> {
        const investmentFees = await this.apiClient.dealGetFeesForCurrentUser({ id, amount })
        return mapOpenApiToInvestmentFeesResponse(investmentFees)
    }

    public async getDealFeeStructure(id: string): Promise<DealFeeStructureType> {
        const dealFeeStructure = await this.apiClient.dealGetFeeStructureForCurrentUser({ id })
        return mapOpenApiToDealFeeStructure(dealFeeStructure)
    }

    public async getPublic(id: string): Promise<DealWithSyndicatePublicType | undefined> {
        const result = await this.apiClient.dealGetPublicRaw({ id })
        if (result.raw.status === 200) return mapOpenApiToDealWithSyndicatePublic(await result.value())
        if (result.raw.status === 204) return undefined
        throw new Error("Failed to load deal (public)")
    }

    public async initializeInvestment(dealId: string, dealInvestmentInitialization: DealInvestmentInitializationType): Promise<InvestmentType> {
        const investment = await this.apiClient.dealInitializeInvestment({
            id: dealId,
            dealInvestmentInitializationOpenApi: mapInitializationToOpenApi(dealInvestmentInitialization)
        })
        return mapOpenApiToInvestment(investment)
    }

    public async addQuestion(dealId: string, question: string): Promise<DealQuestionType> {
        const savedDealQuestion = await this.apiClient.dealQuestionAdd({
            id: dealId,
            dealQuestionAddRequestOpenApi: { question }
        })
        return mapOpenApiToDealQuestion(savedDealQuestion)
    }

    public async toggleFavourite(dealId: string): Promise<void> {
        await this.apiClient.dealToggleFavourite({ id: dealId })
    }

}