import React, {FunctionComponent, useEffect, useState} from "react"
import {UpdateFileType} from "../../../../../update/update.type"
import {useApp} from "../../../../../../tech/app/context/app.context"
import DownloadIcon from "../../../../../../tech/icon/download.component"
import LoadingCircle from "../../../../../../tech/loading/circle/circle.component"
import FileIcon from "../../../../../../tech/icon/file.component"
import PdfIcon from "../../../../../../tech/icon/pdf.component"
import styles from "./file.module.sass"

type CompanyUpdateFileProps = {
    file: UpdateFileType
}

const CompanyUpdateFile: FunctionComponent<CompanyUpdateFileProps> = ({ file }) => {
    const app = useApp()
    const [url, setUrl] = useState<string>()

    useEffect(() => {
        async function fetchUrl() {
            setUrl(await app.fetchClient.fileApi.getDownloadLink(file.file.id!))
        }
        fetchUrl()
    }, [app.fetchClient, file])

    function onClick() {
        if (url) {
            window.open(url, "_blank")
        }
    }

    const pathSplits = file.file.path.split("/")
    const fileName = pathSplits[pathSplits.length - 1]

    return (
        <div
            className={`${styles.file} ${url ? styles.clickable : ""}`}
            onClick={onClick}
        >
            <div className={styles.content}>
                <div className={styles.title}>
                    <div>{file.title}</div>
                </div>
                <div className={styles.fileNameAndIcon}>
                    <div className={styles.icon}>
                        {file.file.mimeType === "PDF" ? (
                            <PdfIcon
                                width={24}
                                height={24}
                                fillClass="fill-icon-theme-light"
                            />
                        ) : (
                            <FileIcon
                                width={24}
                                height={24}
                                strokeClass="stroke-icon-theme-light"
                            />
                        )}
                    </div>
                    <div className={styles.fileName}>
                        {shortenFileName(fileName)}
                    </div>
                </div>
            </div>
            <div className={styles.download}>
                {url ? (
                    <DownloadIcon
                        width={24}
                        height={24}
                        strokeClass="stroke-icon-theme-light"
                    />
                ) : (
                    <LoadingCircle
                        width={18}
                        height={18}
                    />
                )}
            </div>
        </div>
    )
}

export default CompanyUpdateFile

function shortenFileName(fileName: string): string {
    const maxLength = 24
    const lastDotIndex = fileName.lastIndexOf(".")

    const baseName = fileName.slice(0, lastDotIndex)
    const extension = fileName.slice(lastDotIndex)

    if (fileName.length > maxLength) {
        const shortenedBase = baseName.slice(0, maxLength - 3 - extension.length) + "[...]"
        return shortenedBase + extension
    }

    return fileName;
}