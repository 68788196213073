import React, {FunctionComponent} from "react"
import {DealOnboardingDependentType} from "../../onboarding-dependent.type"
import styles from "./title.module.sass"

type DealTitleProps = {
    deal: DealOnboardingDependentType
}

const DealTitle: FunctionComponent<DealTitleProps> = ({ deal }) => {
    return (
        <div className={styles.title}>
            <div>
                <div>{deal.name}</div>
            </div>
        </div>
    )
}

export default DealTitle