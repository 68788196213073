import React, {FunctionComponent} from "react"
import Imgix from "react-imgix"
import {FileType} from "../../domain/file/file.type"

type ImageProps = {
    image: FileType
    height: number
    alt: string
}

const Image: FunctionComponent<ImageProps> = ({
    image,
    height,
    alt
}) => {
    if (image.source.type === "IMGIX_SCR_SET") {
        return (
            <img
                srcSet={image.source.src}
                alt={alt}
                style={{ maxHeight: height }}
            />
        )
    }

    return (
        <Imgix
            src={image.source.src}
            height={height}
            htmlAttributes={{ alt }}
        />
    )
}

export default Image