import {SyndicateForPresentationOpenApi} from "../../generated"
import {SyndicateType} from "./syndicate.type"
import {mapOpenApiToFile} from "../file/file.mapper"

export function mapOpenApiToSyndicate(syndicate: SyndicateForPresentationOpenApi): SyndicateType {
    return {
        id: syndicate.id,
        created: syndicate.created,
        modified: syndicate.modified,
        name: syndicate.name,
        logo: mapOpenApiToFile(syndicate.logo),
        investorGroupIds: syndicate.investorGroupIds
    }
}