import React, {FunctionComponent, useState} from "react"
import {InvestorOnboardingType} from "../../investor/onboarding/onboarding.type"
import Button from "../../../tech/button/button.component"
import {ButtonState} from "../../../tech/button/state.enum"
import {ButtonStyle} from "../../../tech/button/style.enum"
import {ButtonSize} from "../../../tech/button/size.enum"
import ArrowRightIcon from "../../../tech/icon/arrow-right.component"
import LoadingCircle from "../../../tech/loading/circle/circle.component"
import {useQueryClient} from "react-query"
import styles from "./banner.module.sass"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import {QUERY_KEY__INVESTOR_ONBOARDING} from "../../investor/onboarding/onboarding.query"
import {useApp} from "../../../tech/app/context/app.context"
import Image from "../../../tech/image/image.component"

type InvitedToSyndicateBannerProps = {
    onboarding: InvestorOnboardingType
}

const InvitedToSyndicateBanner: FunctionComponent<InvitedToSyndicateBannerProps> = ({ onboarding }) => {
    const app = useApp()
    const queryClient = useQueryClient()
    const [state, setState] = useState<"NONE" | "LOADING" | "SUCCESS" | "ERROR">("NONE")

    async function join(syndicateId: string) {
        setState("LOADING")
        try {
            const onboarding = await app.fetchClient.syndicateApi.join(syndicateId)
            queryClient.setQueryData(
                QUERY_KEY__INVESTOR_ONBOARDING(),
                onboarding
            )
            setState("SUCCESS")
            setInterval(() => setState("NONE"), 5_000)
        }
        catch (err) {
            setState("ERROR")
        }
    }

    if (state === "SUCCESS") {
        return (
            <div className={styles.success}>
                <Alert
                    type={AlertType.SUCCESS}
                    text="You've successfully joined the syndicate."
                />
            </div>
        )
    }

    if (onboarding.syndicatesWithPendingInvitation.length < 1) return <></>

    const syndicate = onboarding.syndicatesWithPendingInvitation[0]
    return (
        <div className={styles.banner}>
            <div className={styles.logo}>
                <Image
                    image={syndicate.logo}
                    height={60}
                    alt={syndicate.name}
                />
            </div>
            <div>
                <div>
                    <div className={styles.text}>
                        You've been invited to the <strong>{syndicate.name}</strong> syndicate.
                    </div>
                    <div>
                        {state === "NONE" && (
                            <Button
                                label="Join the syndicate"
                                type="button"
                                size={ButtonSize.MINIMAL}
                                state={ButtonState.ACTIVE}
                                style={ButtonStyle.PRIMARY}
                                icon={
                                    <ArrowRightIcon
                                        width={14}
                                        height={14}
                                        fillClass="fill-icon-inactive"
                                    />
                                }
                                iconPosition="LEFT"
                                onClick={() => join(syndicate.id)}
                            />
                        )}
                        {state === "LOADING" && (
                            <LoadingCircle
                                width={14}
                                height={14}
                            />
                        )}
                        {state === "ERROR" && (
                            <div className={styles.error}>
                                Failed to join the syndicate.
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvitedToSyndicateBanner