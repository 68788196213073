import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const PdfIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 25 24"
        >
            <path
                className={fillClass}
                d="M21 14.25a.75.75 0 0 1-.75.75H18v1.5h1.5a.75.75 0 0 1 .75.75.75.75 0 0 1-.75.75H18v1.5a.75.75 0 0 1-.75.75.75.75 0 0 1-.75-.75v-5.25a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75zM8.625 16.125c0 .696-.277 1.364-.769 1.856s-1.16.769-1.856.769h-.75v.75a.75.75 0 0 1-.75.75.75.75 0 0 1-.75-.75v-5.25a.75.75 0 0 1 .75-.75H6c.696 0 1.364.277 1.856.769s.769 1.16.769 1.856zm-1.5 0c0-.298-.119-.585-.33-.796S6.298 15 6 15h-.75v2.25H6c.298 0 .585-.119.795-.329s.33-.497.33-.796zm8.25.75c0 .895-.356 1.753-.989 2.387s-1.491.988-2.386.988h-1.5a.75.75 0 0 1-.75-.75v-5.25a.75.75 0 0 1 .75-.75H12c.895 0 1.754.356 2.387.989s.989 1.491.989 2.387zm-1.5 0c0-.497-.197-.974-.549-1.326S12.497 15 12 15h-.75v3.75H12c.497 0 .974-.198 1.326-.549s.549-.829.549-1.326zM3.75 10.5V3.75a1.5 1.5 0 0 1 1.5-1.5h9a.75.75 0 0 1 .531.219l5.25 5.25a.75.75 0 0 1 .219.531v2.25a.75.75 0 0 1-.75.75.75.75 0 0 1-.75-.75V9h-4.5a.75.75 0 0 1-.75-.75v-4.5H5.25v6.75a.75.75 0 0 1-.75.75.75.75 0 0 1-.75-.75zM15 7.5h2.69L15 4.81V7.5z"
            />
        </svg>
    )
}

export default PdfIcon