import {WaitlistRequestType} from "./waitlist.type"
import {mapContactToOpenApi} from "../contact/contact.mapper"
import {WaitlistRequestOpenApi} from "../../generated"

export function mapWaitlistRequestToOpenApi(waitlistRequest: WaitlistRequestType): WaitlistRequestOpenApi {
    return {
        fullName: waitlistRequest.fullName,
        contact: mapContactToOpenApi(waitlistRequest.contact),
        aiStatusDeclared: waitlistRequest.aiStatusDeclared,
        referralCode: waitlistRequest.referralCode
    }
}