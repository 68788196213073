import React, {FunctionComponent, useEffect, useState} from "react"
import {DealType} from "../../../deal.type"
import styles from "./fees.module.sass"
import {InvestmentFeesType} from "../../../../investment/fee/fees.type"
import Alert from "../../../../../tech/alert/alert.component"
import {AlertType} from "../../../../../tech/alert/type.enum"
import {formatAmountWithCurrency} from "../../../../../tech/format/amount.util"
import {useApp} from "../../../../../tech/app/context/app.context"

type DealInvestmentInitializationAmountFeesProps = {
    amount: number
    deal: DealType
}

const DealInvestmentInitializationAmountFees: FunctionComponent<DealInvestmentInitializationAmountFeesProps> = ({
    amount,
    deal
}) => {
    const app = useApp()
    const [fees, setFees] = useState<InvestmentFeesType>()
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")

    const amountFormatted = (parseFloat(String(amount))).toLocaleString("en", {
        currency: deal._currency,
        style: "currency",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    })

    useEffect(() => {
        const fetch = async () => {
            try {
                setState("LOADING")
                const response = await app.fetchClient.dealApi.getInvestmentsFees(deal.id!, amount)
                if (response.amount === amount) {
                    setFees(response.fees)
                    setState("LOADED")
                }
                else {
                    const response2 = await app.fetchClient.dealApi.getInvestmentsFees(deal.id!, amount)
                    setFees(response2.fees)
                    setState("LOADED")
                }
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [app.fetchClient, amount, deal.id])

    if (state === "ERROR") {
        return (
            <div className={styles.alert}>
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to calculate fees."
                />
            </div>
        )
    }

    return (
        <div className={styles.fees}>
            <div className={styles.amount}>
                <div>
                    Amount
                </div>
                <div>
                    {amountFormatted}
                </div>
            </div>
            <div className={styles.placementFee}>
                <div>
                    Setup fee
                    {fees?.placement.explanation && (
                        <div>
                            <small>
                                {fees.placement.explanation}
                            </small>
                        </div>
                    )}
                </div>
                <div>
                    {state === "LOADING" ? (
                        <i>calculating...</i>
                    ) : (
                        <>
                            {fees!.placement.percent} %{" "}
                            ({fees!.placement.getAmountFormatted(deal._currency)})
                        </>
                    )}
                </div>
            </div>
            <div className={styles.total}>
                <div>
                    Total Amount
                </div>
                <div>
                    {state === "LOADING" ? (
                        <i>calculating...</i>
                    ) : (
                        <>
                            {formatAmountWithCurrency(amount + fees!.placement.amount, deal._currency)}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default DealInvestmentInitializationAmountFees