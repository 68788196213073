import {UpdateType} from "../../../update/update.type"
import {compareDates} from "../../../../tech/sort/sort.util"

export function findLatestUpdate(updates: UpdateType[]): UpdateType | undefined {
    if (updates.length < 1) return undefined
    return updates.sort((a, b) => compareDates(a.date, b.date, "DESCENDING"))[0]
}

export function showMissingUpdatesNotice(updates: UpdateType[]): boolean {
    if (updates.length < 1) return true
    const latestUpdate = findLatestUpdate(updates)!
    const fourMonthsAgo = new Date()
    fourMonthsAgo.setMonth((new Date()).getMonth() - 3)
    return latestUpdate.date < fourMonthsAgo
}