import {TimelineItemStatusType} from "../../../../../tech/layout/timeline/item/item.type"
import {InvestorOnboardingType} from "../../../onboarding/onboarding.type"

export function evaluateAiStatusDeclarationStatus(onboarding: InvestorOnboardingType): TimelineItemStatusType {
    return onboarding.stage.type === "ACCREDITED_INVESTOR_STATUS_TO_BE_DECLARED"
        ? "ACTIVE"
        : "DONE"
}

export function evaluatePersonalDataStatus(onboarding: InvestorOnboardingType): TimelineItemStatusType {
    if (onboarding.stage.type === "GENERAL_DATA") {
        return "ACTIVE"
    }
    if (onboarding.investorWithDetails?.investor.person?.fullName === undefined) {
        return "INACTIVE"
    }
    return "DONE"
}

export function evaluateKycStatus(onboarding: InvestorOnboardingType): TimelineItemStatusType {
    if (onboarding.stage.type === "KYC_DOCUMENT_UPLOAD_NECESSARY") {
        return "ACTIVE"
    }
    const kyc = onboarding.investorWithDetails?.investor.kyc
    if ((kyc && !kyc.complete) || kyc?.needsUpdate) {
        return "INACTIVE"
    }
    return "DONE"
}