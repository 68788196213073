import {
    DealInvestmentFeesResponseOpenApi,
    InvestmentFeesOpenApi,
    InvestmentFeeWithAmountAndExplanationOpenApi,
    InvestmentFeeWithAmountOpenApi
} from "../../../generated"
import {
    InvestmentFeesResponseType,
    InvestmentFeesType,
    InvestmentFeeWithAmountAndExplanationType,
    InvestmentFeeWithAmountType
} from "./fees.type"


export function mapOpenApiToInvestmentFeesResponse(response: DealInvestmentFeesResponseOpenApi): InvestmentFeesResponseType {
    return {
        amount: response.amount,
        fees: mapOpenApiToInvestmentFees(response.fees)
    }
}

export function mapOpenApiToInvestmentFees(fees: InvestmentFeesOpenApi): InvestmentFeesType {
    return {
        placement: mapOpenApiToInvestmentFeeWithAmountAndExplanation(fees.placement),
        management: fees.management,
        carry: fees.carry,
        exchange: fees.exchange && mapOpenApiToInvestmentFeeWithAmount(fees.exchange)
    }
}

export function mapOpenApiToInvestmentFeeWithAmount(feeWithAmount: InvestmentFeeWithAmountOpenApi): InvestmentFeeWithAmountType {
    return new InvestmentFeeWithAmountType({
        amount: feeWithAmount.amount,
        percent: feeWithAmount.percent
    })
}

export function mapOpenApiToInvestmentFeeWithAmountAndExplanation(
    feeWithAmountAndExplanation: InvestmentFeeWithAmountAndExplanationOpenApi
): InvestmentFeeWithAmountAndExplanationType {
    return new InvestmentFeeWithAmountAndExplanationType({
        amount: feeWithAmountAndExplanation.amount,
        percent: feeWithAmountAndExplanation.percent,
        explanation: feeWithAmountAndExplanation.explanation
    })
}