import React, {FunctionComponent} from "react"
import PdfViewer from "../../../../tech/pdf/pdf.component"
import {FileType} from "../../../file/file.type"
import styles from "./slide-deck.module.sass"

type DealSlideDeckProps = {
    slideDeck: FileType | undefined
}

const DealSlideDeck: FunctionComponent<DealSlideDeckProps> = ({ slideDeck }) => {
    if (!slideDeck || slideDeck.source.type !== "PUBLIC_S3_URL") return <></>

    return (
        <div className={styles.deck}>
            <PdfViewer url={slideDeck.source.src}/>
        </div>
    )
}

export default DealSlideDeck