import React, {FunctionComponent} from "react"
import {UpdateType} from "../../../../update/update.type"
import PlusCircleIcon from "../../../../../tech/icon/plus-circle.component"
import MinusCircleIcon from "../../../../../tech/icon/minus-circle.component"
import styles from "./update.module.sass"
import CompanyUpdateFile from "./file/file.component"

type CompanyUpdateProps = {
    opened: boolean
    setOpenUpdateId: (id: string | undefined) => void
    update: UpdateType
}

const CompanyUpdate: FunctionComponent<CompanyUpdateProps> = ({
    opened,
    setOpenUpdateId,
    update
}) => {
    return (
        <div
            className={styles.update}
            key={`update-${update.id!}`}
        >
            <div
                className={styles.head}
                onClick={() => setOpenUpdateId(opened ? undefined : update.id)}
            >
                <div className={styles.quarter}>
                    <div>
                        {transformToQuarter(update.date)}
                    </div>
                </div>
                <div className={styles.toggle}>
                    <div>
                        {opened ? (
                            <MinusCircleIcon
                                width={24}
                                height={24}
                                fillClass="fill-icon-inactive"
                            />
                        ) : (
                            <PlusCircleIcon
                                width={24}
                                height={24}
                                fillClass="fill-icon-inactive"
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className={`${styles.content} ${opened ? styles.opened : ""}`}>
                <div className={styles.inner}>
                    {update.description}
                    {update.files.length > 0 && (
                        <div className={styles.files}>
                            {update.files.map(file => (
                                <CompanyUpdateFile
                                    file={file}
                                    key={`update-file-${file.file.id}`}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default CompanyUpdate

function transformToQuarter(date: Date): string {
    const quarter = Math.floor(date.getMonth() / 3) + 1
    return `Q${quarter} ${date.getFullYear()}`
}