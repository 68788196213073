import React, {FunctionComponent} from "react"
import {InvestorOnboardingType} from "../../onboarding/onboarding.type"
import ProfileTile from "../tile/tile.component"
import {PersonType} from "../../../person/person.type"
import styles from "./investor.module.sass"
import {mapCountryToString} from "../../../address/country/country.mapper"
import {AddressType} from "../../../address/address.type"

type ProfileInvestorProps = {
    onboarding: InvestorOnboardingType
}

const ProfileInvestor: FunctionComponent<ProfileInvestorProps> = ({
    onboarding,
}) => {
    if (!onboarding.investorWithDetails?.investor?.person) {
        return <></>
    }

    const person = onboarding.investorWithDetails.investor.person
    return (
        <ProfileTile
            title={getTitle(person)}
            collapsible={false}
            children={
                <div className={styles.person}>
                    <div className={styles.data}>
                        <div className={styles.emailAndPhone}>
                            <div>
                                {person.contact.email}
                            </div>
                            {person.contact.phone && (
                                <>
                                    <div className={styles.spacer}>
                                        <div/>
                                    </div>
                                    <div className={styles.phone}>
                                        {person.contact.phone}
                                    </div>
                                </>
                            )}
                        </div>
                        {person.address && (
                            <div className={styles.address}>
                                {getFullAddress(person.address)}
                            </div>
                        )}
                        {onboarding.investorWithDetails.investor.companyName && (
                            <div className={styles.companyName}>
                                <strong>Company:</strong> {onboarding.investorWithDetails.investor.companyName}
                            </div>
                        )}
                    </div>
                </div>
            }
        />
    )
}

export default ProfileInvestor

function getFullAddress(address: AddressType): string {
    const parts = []
    address.line1 && parts.push(address.line1)
    address.zipOrPostalCode && parts.push(address.zipOrPostalCode)
    address.city && parts.push(address.city)
    address.country && parts.push(mapCountryToString(address.country))
    return parts.join(", ")
}

function getTitle(person: PersonType): string {
    return person.fullName
        ? person.fullName
        : person.contact.email
}